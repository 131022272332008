<script>
//Resource dropdown is a reusable component used in ag-grid table to display searchable dropdown.
import { defineComponent } from "vue";

export default defineComponent({
  data: function () {
    return {
      cellValue: null,
      loading: true,
      query: "",
      initialQuery: "",
      relatedManager: null,
      resourceQueryResults: [],
      hasSearched: false,
      hasSelected: false,
      focused: false,
      cellEditorParams: {},
      deselectTimeOut: {
        default: 500,
      },
    };
  },
  watch: {
    async query(value) {
      if (!this.params.colDef.cellEditorParams.resourceValues) {
        this.searchValues(value);
      }
    },
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    // see ICellRendererParams below for more details
    this.query = this.getValueToDisplay(this.params);
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh(params) {
      // set value into cell again
      this.query = this.getValueToDisplay(params);
    },

    isPopup() {
      return true;
    },

    getValue() {
      this.query;
    },

    getValueToDisplay(params) {
      return params.valueFormatted ? params.valueFormatted : params.value;
    },
    onFocus(e) {
      this.focused = true;
      this.hasSearched = true;
      this.$refs.queryInput.focus();
      this.$refs.queryInput.select();
    },
    selectResource(resource) {
      this.hasSearched = false;
      this.hasSelected = true;
      this.query = resource[this.cellEditorParams.changedField];
      this.params.data[this.params.column.colId] =
        resource[this.cellEditorParams.field];
      this.cellEditorParams.changedVal = this.query;
      this.params.stopEditing();
    },
    async searchValues(value = "") {
      let queryParams = {
        page: 1,
        page_size: this.params.colDef.cellEditorParams.page_size(),
      };
      queryParams[
        `${this.params.colDef.cellEditorParams.changedField}__contains`
      ] = value;
      let result = await this.params.colDef.cellEditorParams.resource
        .wrap("/", queryParams)
        .get();
      if (result) {
        this.resourceQueryResults = result.data.result;
      }
    },
  },

  async mounted() {
    this.cellEditorParams = this.params.colDef.cellEditorParams;
    this.query = this.getValueToDisplay(this.params);
    if (this.params.colDef.cellEditorParams.resourceValues) {
      this.resourceQueryResults = await this.cellEditorParams.resourceValues();
    } else {
      this.searchValues();
    }
    this.loading = false;
  },
});
</script>

<template>
  <div>
    <input
      type="text"
      v-model="query"
      class="form-control"
      @focus="onFocus"
      ref="queryInput"
      autocomplete="off"
    />
    <div class="typeahead-options">
      <ul class="list-group" v-if="hasSearched && !hasSelected">
        <li
          class="list-group-item"
          role="button"
          v-for="(resource, i) in resourceQueryResults"
          :key="i"
        >
          <a tabindex="-1" @click="selectResource(resource)">
            <span> {{ resource[this.cellEditorParams.changedField] }} </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style>
.typeahead {
  position: relative;
}
.typeahead-options {
  position: absolute;
  top: 40px;
  left: 0px;
  width: 100%;
  text-align: left;
}
</style>
