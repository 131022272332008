
//Resource List component is used to display data using ag-grid table with pagination.

import { defineComponent } from "vue";
import { AgGridVue } from "ag-grid-vue3";

export default defineComponent({
  name: "ResourceList",
  components: {
    AgGridVue,
  },
  props: {
    columnDefs: {
      type: Array,
      required: true,
    },
    rowData: {
      type: Array,
      required: true,
    },
    resourceName: {
      type: String,
      required: true,
    },
    pagination: {
      type: Boolean,
      required: true,
    },
    paginationPageSize: {
      type: Number,
      required: true,
    },
    frameworkComponents: {
      type: Object,
    },
    editType: {
      type: String,
      required: true,
    },
    rowSelection: {
      type: String,
    },
    paginationData: {
      type: Object,
      required: true,
    },
    btn_options: {
      type: Object,
    },
    search: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  watch: {
    searchValue(value) {
      this.$emit("getRows", 1, value);
    },
    range: {
      handler: function () {
        this.$emit("getRows", 1, this.searchValue, null, this.range || {});
      },
      deep: true,
    },
  },
  data() {
    return {
      gridOptions: {},
      gridApi: {},
      columnApi: {},
      errorMessages: {},
      editedValues: {},
      searchValue: "",
      range: {
        start: "",
        end: "",
      },
      date: "",
      modelConfig: { type: "string", mask: "MM/DD/YYYY" },
    };
  },
  beforeMount() {
    this.gridOptions = { debug: true };
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    // this.gridColumnApi = this.gridOptions.columnApi;
  },
  methods: {
    onGridReady(params: any) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    async onRowValueChanged(event: any) {
      if (Object.keys(this.editedValues).length) {
        this.$emit("success", {
          resource: this.editedValues,
          _id: event.data._id,
        });
        this.editedValues = {};
      }
    },
    onCellValueChanged(event: any) {
      if (event.value && event.newValue != event.oldValue) {
        this.editedValues[`${event.column.colId}`] = event.column.colDef
          .cellEditor
          ? event.data[`${event.column.colId}`]
          : event.value;
      }
    },
    onCellClicked(event: any) {
      if (event.colDef.headerName != "Actions") {
        this.$emit("rowClicked", event.data);
      }
    },
    clearFilters() {
      if (this.searchValue) {
        this.searchValue = "";
      }

      if (this.range.start || this.range.end) {
        this.range = {
          start: "",
          end: "",
        };
      }
    },
  },
});
